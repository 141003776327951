// Masthead
//
// Super small header above the content for site name and short description.

.header {
	width: 100%;
	background-color: #19191b;
	transition: background-color 0.5s linear;
	box-shadow: 0 4px 5px 0 rgba(0,0,0,0.14),0 1px 10px 0 rgba(0,0,0,0.12),0 2px 4px -1px rgba(0,0,0,0.3);
}

.header nav a{
	color: #FFF;
}

.logo {
	display:inline-block;
}

#collapse-toggle:hover {
	cursor: unset;
}

.button-list {
	padding: 0;
	display: inline-block; 
	list-style: none;
	margin: 0;
}

.button-list li {
	display: inline-block;
}

.button-list a, #collapse-toggle {
	display: inline-block;
	text-decoration: none;
	color: inherit;
}

.button-list a:visited, #collapse-toggle:visited {
	color: inherit;
}

nav {
	display: flex;
	flex-flow: row wrap;
	max-width: 1024px;
	margin: 0 auto;
	padding: 0 10px;
}

nav  > * {
	flex-grow: 1;
}

nav .button-list a, .logo {
	font-size: 1.3em;
	padding: 15px 10px;
	margin-right: 5px;
}

nav .button-list {
	text-align: right;
}

nav .button-list a {
	box-shadow: 0 0px 0 #fff inset;
	transition: box-shadow 0.1s linear;
}

nav .button-list a:hover { 
	box-shadow: 0 -2px 0 #fff inset;
	color: #fff;
}

.collapsable{
	display: none;
}


@media (max-width: 680px) {

	nav {
		padding: 0;
		overflow: hidden;
	}
	nav .button-list {
		text-align: center;
		display: none;
	}
	#collapse-toggle:hover {
		cursor: pointer;
	}
	.logo {
		text-align: center;
		margin-right: 0;
	}
	.collapsable {
		display: inline;
	}
	nav .expand-nav {
		display: block;
	}
}