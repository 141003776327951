body {
	background-repeat:no-repeat;
	background-size: cover;
}

.content {
	margin-bottom: 10px;
	margin-top: 10px;
}

.content-border {
	border-radius: 3px;
	border: 1px solid #000;
}

.info-box {
	background-color: #19191b;
	// padding: 30px;
	color: rgb(211, 211, 211);
	padding: 0px 10px 0px 20px;
}

.divider {
	margin-bottom: 10px;
	border-bottom: 1px solid #eee;
}

.note {
	font-style:italic;
	color: #536F81;
}
.note:before {
	content:"- "
}

.info-box ul {
	list-style:none;
}
.info-box ul ul {
	border-left:1px dashed rgba(255,255,255,0.2);
}
.info-box li {
	padding:5px;
	padding-left:10px;
}

.bullet  {
	list-style: none;
}
.bullet li:before {
	content: '';
	width: 0.5rem;
	height: 0.5rem;
	background-color: rgb(226, 226, 226);
	border-radius: 0.25rem;
	display: block;
	position: absolute;
	left: 0;
	top: 0.5rem;
}
.bullet li {
	position: relative;
	padding:0;
	padding-left:15px;
}

.plus:before {
	color: rgb(0,200,75);
  content:"+";
	padding-right:5px;
}
.maybe:before {
	color: rgb(220,200,0);
  content:"*";
	padding-right:5px;
}
.no-interest:before {
	color: rgb(200,200,200);
	content:"-";
	padding-right:5px;
}
.dislike:before {
	color: rgb(255,0,0);
	content:"x";
	padding-right:5px;
}

a {
	color:#ABBFD4;
}

a:visited {
	color: #ABBFD4;
}

a:hover {
	color: rgb(213, 232, 252);
}

h1 {
	font-weight: 300;
	font-size: 2em;
}
h2:target {
	color: #FFF;
}

.content-img {
	width: 100%;
	padding-top: 10px;
}
.sfw-toggle{
	text-align:center;
	font-size: 1.5em;
	cursor:pointer;
	color: rgb(211, 211, 211);
}

.sfw-toggle span:hover{
	color:#FFF;

}

.highlighted {
	color: #FFF;
}

.story-iframe {
	width: 100%;
}