@import url('https://fonts.googleapis.com/css?family=Roboto:400,300');

html {
	height: 100%;
}

body {
	height: 100%;
	margin: 0;
	// background-color: #0f0f0f;
	// font-family: 'Roboto', sans-serif;
	// color: rgb(245, 245, 245);
	background-repeat: no-repeat;
	background-size: cover;
	background-attachment: fixed;
	background-position: center;
}

h1, h2, h3 {
	font-weight: 300;
	color: rgb(255, 152, 16);

}

.wrapper {
	display: flex;
	flex-direction: column;
	height: 100%;
}

.content { 
	flex: 1 0 auto;
	padding: 0 10px;
	padding-bottom: 10px;
	width: 100%;
}

.content.center img{
	max-width: 100%;	
}

.notice {
    position: relative;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 35px;
    text-align: center;
    color: #FFF;
    text-shadow: 0 1px 2px black;
}

.clean-link {
	text-decoration: none;
	color: inherit;
}

.clean-link:visited {
	text-decoration: none;
	color: inherit;
}


footer {
	background-color: #19191b;
	flex-shrink: 0;
}

.small-text {
	font-size: 0.75em;
	text-align: center;
	margin-top: -10px;
	opacity: 0.3
}

.media-links {
	display: block;
	text-align: center;
}

.media-links img {
	max-height: 70px;
	margin-right: 10px;
	padding: 15px 10px;
}
.file-link {
	float: right;
	font-size: 70%;
	text-decoration: none;
}


.center {
	max-width: 1100px;
	margin: 0 auto;
}

.img-404 {
	margin: 0 auto;
	display: block;
}

.title-404{
	font-size: 120px;
}

.page-not-found {
	text-align: center;
}

